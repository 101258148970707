import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { CardCTA, Header, SectionJobs, Text } from "components";
import { Layout } from "containers";
import { getPageFilters } from "utils/page";

const PageJobs = ({
  data: {
    config: { header, seo },
    imageCategories,
    imageCreative,
    jobCategories,
    jobs,
    site: {
      siteMetadata: { subscribe },
    },
  },
  location,
}) => {
  const hasJobs = jobs?.edges?.length > 0;

  const layoutProps = {
    seoProps: seo,
  };

  const headerProps = {
    captionProps: {
      className: hasJobs ? "space-right-image" : null,
    },
    headingProps: !hasJobs && {
      className: "cta",
    },
    ...header,
  };

  const sectionJobsProps = {
    className: !hasJobs ? "cta" : null,
    containerProps: !hasJobs && {
      className: "cta",
      maxWidth: "md",
    },
    filters: getPageFilters(jobCategories, "/jobs"),
    jobs,
    location,
    mascot: {
      className: imageCreative.name,
      ...imageCreative,
    },
  };

  const cardCTAProps = {
    button: {
      href: subscribe,
      label: "Subscribe",
      target: "_blank",
    },
    className: "featured",
    content: (
      <>
        <Text>
          Hey there, fellow creatives! We're working with the coolest remote
          companies to find you the coolest jobs.
        </Text>
        <Text>
          Subscribe below to get those right to your inbox. No spam, ever. Your
          email's safe with us.
        </Text>
      </>
    ),
    heading: "Subscribe for remote gigs!",
    image: {
      className: "featured",
      ...imageCategories,
    },
  };

  return (
    <Layout {...layoutProps}>
      <Header {...headerProps} />
      <SectionJobs {...sectionJobsProps}>
        {!hasJobs && <CardCTA {...cardCTAProps} />}
      </SectionJobs>
    </Layout>
  );
};

PageJobs.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    imageCategories: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
    }),
    imageCreative: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
    }),
    jobCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.oneOf([
            "Art",
            "Audio",
            "Design",
            "Development",
            "Social",
            "Video",
            "Writing",
          ]),
        })
      ),
    }),
    jobs: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              companyLogo: PropTypes.oneOfType([
                PropTypes.shape({
                  localFiles: PropTypes.arrayOf(
                    PropTypes.shape({
                      childImageSharp: PropTypes.shape({
                        fluid: PropTypes.shape({
                          base64: PropTypes.string,
                          aspectRatio: PropTypes.number,
                          src: PropTypes.string,
                          srcSet: PropTypes.string,
                          sizes: PropTypes.string,
                        }),
                      }),
                      name: PropTypes.string,
                    })
                  ),
                }),
                PropTypes.string,
              ]),
              companyName: PropTypes.string,
              date: PropTypes.string,
              id: PropTypes.string,
              jobCategory: PropTypes.oneOf([
                "Art",
                "Audio",
                "Design",
                "Development",
                "Social",
                "Video",
                "Writing",
              ]),
              jobTitle: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        subscribe: PropTypes.string,
      }),
    }),
  }),
};

export const jobsPageQuery = graphql`
  query JobsPageQuery {
    config: pagesYaml(id: { eq: "PageJobs" }) {
      header {
        heading
      }
      id
      seo {
        title
      }
    }
    imageCategories: file(name: { eq: "mascot-categories" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90, traceSVG: { color: "#ced0d4" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      name
    }
    imageCreative: file(name: { eq: "mascot-creative" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90, traceSVG: { color: "#ced0d4" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      name
    }
    jobCategories: allAirtable(
      filter: { data: { isPublished: { eq: 1 } }, table: { eq: "Jobs" } }
    ) {
      group(field: data___jobCategory) {
        category: fieldValue
      }
    }
    jobs: allAirtable(
      filter: { data: { isPublished: { eq: 1 } }, table: { eq: "Jobs" } }
      # limit: 100
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          data {
            # NOTE: use this query if companyLogo is an attachment type
            companyLogo {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 480, quality: 90) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            companyLogoUrl
            companyName
            date(formatString: "MMMM DD, YYYY")
            id
            jobCategory
            jobTitle
          }
          id
        }
      }
    }
    site {
      siteMetadata {
        subscribe
      }
    }
  }
`;

export default PageJobs;
